import React from 'react';
import AuthWrapper from '../../components/layout/AuthWrapper';
import Head from '../../components/layout/Head';
import { LayoutBlank } from '../../components/layout/Layout';
import FillForm from '../../components/organisms/Form/FillForm';

const EmployerForm = () => {
  return (
    <AuthWrapper>
      <Head title="Employer Form" />
      <LayoutBlank>
        <FillForm userType="employer" />
      </LayoutBlank>
    </AuthWrapper>
  );
};

export default EmployerForm;
